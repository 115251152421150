import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import photo from '../../img/icons/IMG 1.png';
import photo2 from '../../img/icons/IMG 2.png';
import photo3 from '../../img/icons/IMGG3.png';
 
const AProposV2 = () => {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const videoRef = useRef(null);
    const history = useHistory();
 
    useEffect(() => {
        const playVideo = () => {
            if (videoRef.current) {
                videoRef.current.play().catch(error => console.log("Lecture auto bloquée :", error));
            }
        };
 
        playVideo();
 
        const interval = setInterval(() => {
            if (videoRef.current && videoRef.current.paused) {
                videoRef.current.play();
            }
        }, 1000);
 
        return () => clearInterval(interval);
    }, []);
 
    return (
        <div className="ltn__about-us-area pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-30">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color mt-30">
                                    À propos de nous
                                </h6>
                                <h1 className="text-left">Découvrez notre accompagnement sur <br/> mesure</h1>                            </div>
                            <p>
                                La recherche d’un appartement est une étape importante, mais elle peut s’avérer complexe et fastidieuse sans accompagnement.
                                Nous sommes là pour vous épauler, vous conseiller et vous inspirer à chaque étape,
                                afin de simplifier votre recherche et de concrétiser votre projet.
                            </p>
                            <div className="d-flex align-items-center mt-4">
                                <div className="btn-wrapper text-center mt-0 go-top">
                                    <button
                                        type="button"
                                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                        onClick={() => history.push('/about')}
                                    >
                                        Découvrir
                                    </button>
                                </div>
                            </div>
                            <ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
                                <li><img src={photo} style={{ height: '300px' }} alt="Image" /></li>
                                <li><img src={photo2} style={{ height: '300px' }} alt="Image" /></li>
                                <li><img src={photo3} style={{ height: '300px' }} alt="Image" /></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-img-wrap about-img-right" style={{ position: "relative" }}>
                            <video
                                ref={videoRef}
                                className="about-us-video"
                                width="100%"
                                autoPlay
                                loop
                                muted
                                disablePictureInPicture
                                controlsList="nodownload nofullscreen noremoteplayback"
                                onContextMenu={(e) => e.preventDefault()}
                                onMouseEnter={() => videoRef.current.play()}
                            >
                                <source src={publicUrl + "assets/media/14.mp4"} type="video/mp4" />
                                Votre navigateur ne supporte pas la lecture de vidéos.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default AProposV2;