import React, { Component } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

class ServiceV5 extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
      once: false,
      offset: 100,
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div className="ltn__service-area section-bg-1 pt-30 pb-70 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Nos Valeurs
                </h6>
                <h1 className="section-title">Ce qui nous définit</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {[
              {
                title: "Empathie",
                description:
                  "Patience et  bienvellance pour mieux comprendre votre projet.",
                icon: "flaticon-house",
              },
              {
                title: "Ecoute",
                description:
                  "Comprendre vos priorités , vos souhait et valoriser vos choix .",
                icon: "flaticon-mortgage",
              },
              {
                title: "Objectivite et transparence",
                description:
                  "Vous apporter les meilleurs solutions dans vos intérêts.",
                icon: "flaticon-operator",
              },
              {
                title: "Engagement",
                description: "Investissement total dans votre projet.",
                icon: "flaticon-house-1",
              },
            ].map((value, index) => (
              <div className="col-lg-3 col-sm-6 col-12" key={index}>
                <div
                  className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1"
                  style={{ padding: "30px 20px", height: "290px" }}
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                >
                  <div className="ltn__feature-icon">
                    <span>
                      <i className={value.icon} />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h3 style={{ marginBottom: "10px" }}>{value.title}</h3>
                    <p style={{ fontSize: "14px", lineHeight: "1.4" }}>
                      {value.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <style>
          {`
			 .ltn__feature-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.ltn__feature-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 `}
        </style>
      </div>
    );
  }
}
export default ServiceV5;
