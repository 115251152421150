import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { actGetAppartements } from "../../slices/appartement/AppartSlice";
import { useDispatch, useSelector } from "react-redux";
import MapDialog from "./MapDialog";

const BannerV6 = () => {
  const publicUrl = process.env.PUBLIC_URL + "/";
  const history = useHistory();
  const [showMapDialog, setShowMapDialog] = useState(false);
  const [departement, setDepartement] = useState("");
  const [rooms, setRooms] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const dispatch = useDispatch();
  const { records } = useSelector((state) => state.appartement);

  useEffect(() => {
    dispatch(actGetAppartements());

    // Add window resize listener for responsive behavior
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const roomOptions = [
    { value: "", label: "Nombre de pièces" },
    { value: "0", label: "Studio" },
    { value: "1", label: "1 pièce" },
    { value: "2", label: "2 pièces" },
    { value: [3, Infinity], label: "3 pièces ou plus" },
  ];

  const Departement = [
    { value: "", label: "Département" },
    { value: "Paris", label: "Paris (75)" },
    { value: "Seine-et-Marne", label: "Seine-et-Marne (77)" },
    { value: "Yvelines", label: "Yvelines (78)" },
    { value: "Essonne", label: "Essonne (91)" },
    { value: "Hauts-de-Seine", label: "Hauts-de-Seine (92)" },
    { value: "Seine-Saint-Denis", label: "Seine-Saint-Denis (93)" },
    { value: "Val-de-Marne", label: "Val-de-Marne (94)" },
  ];

  const handleMapClick = () => {
    setShowMapDialog(true);
  };

  const handleCloseMapDialog = () => {
    setShowMapDialog(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    let roomsQuery;
    if (Array.isArray(rooms)) {
      roomsQuery = `${rooms[0]}-`;
    } else if (rooms !== "") {
      roomsQuery = rooms;
    } else {
      roomsQuery = "";
    }

    let url = `/shop-right-sidebar?`;
    if (departement) {
      url += `departement=${departement}&`;
    }
    if (roomsQuery !== "") {
      url += `rooms=${roomsQuery}`;
    }

    history.push(url);
  };

  // Responsive styles for select components
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: isMobile ? "50px" : "60px",
      height: isMobile ? "50px" : "60px",
      borderRadius: "0",
      border: "1px solid #e5e5e5",
      boxShadow: "none",
      fontSize: isMobile ? "14px" : "16px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: isMobile ? "50px" : "60px",
      padding: isMobile ? "0 10px" : "0 15px",
      display: "flex",
      alignItems: "center",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: isMobile ? "50px" : "60px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: isMobile ? "14px" : "16px",
      color: "#777",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: isMobile ? "14px" : "16px",
      color: "#222",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: isMobile ? "14px" : "16px",
      color: state.isSelected ? "#fff" : "#222",
      backgroundColor: state.isSelected
        ? "#222"
        : state.isFocused
        ? "#f0f0f0"
        : null,
      padding: isMobile ? "8px 10px" : "10px 15px",
      "&:active": {
        backgroundColor: "#222",
        color: "#fff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  // Vidéo style pour garantir une bonne couverture responsive
  const videoStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "100%",
    minHeight: "100%",
    width: "auto",
    height: "auto",
    objectFit: "cover",
    zIndex: -1,
  };

  return (
    <div className="ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg">
      <div
        className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active"
        style={{
          position: "relative",
          overflow: "hidden",
          height: isMobile ? "100vh" : "auto",
        }}
      >
        <video autoPlay muted loop id="myVideo">
          <source src={publicUrl + "assets/media/5.mp4"} type="video/mp4" />
        </video>
        <div
          className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-overlay-theme-black-30"
          style={{ minHeight: isMobile ? "100vh" : "650px" }}
        >
          <div className="ltn__slide-item-inner text-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <div
                    className="slide-item-car-dealer-form"
                    style={{ padding: isMobile ? "15px" : "30px" }}
                  >
                    <div className="section-title-area ltn__section-title-2 text-center">
                      {/* Titre agrandi avec classe CSS plutôt que du style inline pour maintenir la responsivité existante */}
                      <h1
                        className="section-title text-color-white ltn__larger-title"
                        style={{
                          fontSize: isMobile ? "32px" : "50px",
                          marginBottom: isMobile ? "15px" : "30px",
                        }}
                      >
                        LA CLE DE VOTRE
                        <span
                          className="ltn__secondary-color-3"
                          style={{ fontSize: "inherit" }}
                        >
                          {" "}
                          NOUVELLE VIE{" "}
                        </span>
                      </h1>
                    </div>
                    {/* <div className="ltn__car-dealer-form-tab">
                      <div className="tab-content pb-12">
                        <div
                          className="tab-pane fade active show"
                          id="ltn__form_tab_1_1"
                        >
                          <div className="car-dealer-form-inner">
                            <form
                              onSubmit={handleSearch}
                              className="ltn__car-dealer-form-box row"
                            >
                              <div
                                className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car ${
                                  isMobile ? "col-12" : "col-lg-3 col-md-6"
                                }`}
                                style={{
                                  marginBottom: isMobile ? "15px" : "0",
                                }}
                              >
                                <Select
                                  options={roomOptions}
                                  onChange={(e) => setRooms(e.value)}
                                  styles={customStyles}
                                  placeholder="Nombre de pièces"
                                  isSearchable={false}
                                />
                              </div>
                              <div
                                className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter ${
                                  isMobile ? "col-12" : "col-lg-3 col-md-6"
                                }`}
                                style={{
                                  marginBottom: isMobile ? "15px" : "0",
                                }}
                              >
                                <Select
                                  options={Departement}
                                  onChange={(e) => setDepartement(e.value)}
                                  styles={customStyles}
                                  placeholder="Département"
                                  isSearchable={false}
                                />
                              </div>
                              <div
                                className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar ${
                                  isMobile ? "col-12" : "col-lg-3 col-md-6"
                                }`}
                                style={{
                                  marginBottom: isMobile ? "15px" : "0",
                                }}
                              >
                                <div className="btn-wrapper text-center mt-0 go-top">
                                  <button
                                    type="submit"
                                    className="btn theme-btn-1 btn-effect-1 text-uppercase w-100"
                                    style={{
                                      height: isMobile ? "50px" : "60px",
                                    }}
                                  >
                                    Rechercher
                                  </button>
                                </div>
                              </div>
                              <div
                                className={`ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar ${
                                  isMobile ? "col-12" : "col-lg-3 col-md-6"
                                }`}
                              >
                                <div className="btn-wrapper text-center mt-0 go-top">
                                  <button
                                    onClick={handleMapClick}
                                    type="button"
                                    className="btn theme-btn-1 btn-effect-1 text-uppercase w-100"
                                    style={{
                                      height: isMobile ? "50px" : "60px",
                                    }}
                                  >
                                    Afficher la carte
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MapDialog
        show={showMapDialog}
        onClose={handleCloseMapDialog}
        properties={records}
      />
    </div>
  );
};

export default BannerV6;
