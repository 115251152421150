import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
 
class CaracteristiquesV2 extends Component {
 
    componentDidMount() {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }
 
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let classePersonnalisee = this.props.customClass ? this.props.customClass : '';
 
        const services = [
            { img: "app8.jpg", title: "Expertise Locale", description: "Notre équipe maîtrise le marché immobilier local et peut vous conseiller sur les meilleures zones pour investir ou s'installer." },
            { img: "app9.jpg", title: "Accompagnement Personnalisé", description: "Chaque client est unique. Nous personnalisons nos services selon vos besoins, que vous soyez primo-accédant ou investisseur aguerri." },
            { img: "app7.jpg", title: "Transparence et Fiabilité", description: "Nous croyons en une communication ouverte. Vous pouvez compter sur nous pour toutes les informations nécessaires à votre prise de décision." }
        ];
 
        return (
            <div className={classePersonnalisee}>
                <div className="container">
                    <h1 className="section-title">Pourquoi choisir appartement 9 ?</h1>
                    <div className="container">
                        <div className="cards-container">
                            <div className="cards-wrapper">
                                {services.map((service, index) => (
                                    <div key={index} className="custom-card" data-aos="fade-up">
                                        <div className="image-container">
                                            <img
                                                src={publicUrl + "assets/img/appart9/" + service.img}
                                                alt={service.title}
                                                className="card-image"
                                            />
                                        </div>
                                        <div className="text-content">
                                            <h3 className='center'>{service.title}</h3>
                                            <p>{service.description}</p>
                                            <div className="card-link">
                                                <Link className="ltn__service-btn" to="/shop">Trouver une maison <i className="flaticon-right-arrow" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <style>
                            {`
                            .section-title {
                                text-align: center;
                                margin-bottom: 30px;
                            }
                            .cards-container {
                                display: flex;
                                justify-content: center;
                                width: 100%;
                                overflow: hidden;
                                margin-left: 40px;
                            }
                            .cards-wrapper {
                                display: flex;
                                gap: 40px;
                                transition: transform 0.3s ease-in-out;
                            }
                            .custom-card {
                                background: white;
                                border-radius: 12px;
                                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                                overflow: hidden;
                                width: calc(85% / 3);
                                min-width: calc(60% / 3);
                                flex: 0 0 auto;
                                display: flex;
                                flex-direction: column;
                            }
                            .custom-card:hover {
                                transform: translateY(-10px);
                                box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
                            }
                            .image-container {
                                width: 100%;
                                height: 200px;
                                overflow: hidden;
                            }
                            .card-image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                            .text-content {
                                padding: 20px;
                                flex-grow: 1;
                                display: flex;
                                flex-direction: column;
                            }
                            .text-content h3 {
                                font-size: 1.5rem;
                                font-weight: bold;
                                margin-bottom: 10px;
                            }
                            .text-content p {
                                font-size: 1rem;
                                color: #555;
                                flex-grow: 1;
                            }
                            .center{
                                 text-align: center}
                            .card-link {
                                margin-top: auto;
                                padding-top: 10px;
                                text-align: center;
                            }
                            .ltn__service-btn {
                                display: inline-block;
                                text-decoration: none;
                                color: #000;
                                font-weight: bold;
                            }
                            @media (max-width: 768px) {
                                .custom-card {
                                    width: calc(100% - 40px);
                                    min-width: calc(100% - 40px);
                                }
                            }
                        `}
                        </style>
                    </div>
                </div>
            </div>
        )
    }
}
 
export default CaracteristiquesV2;