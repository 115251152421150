import React, { useState, useEffect } from "react";

const ResponsiveAnimatedComparison = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [highlightLeft, setHighlightLeft] = useState(false);
  const [highlightRight, setHighlightRight] = useState(false);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 1200
  );

  useEffect(() => {
    // Trigger entrance animation after component mount
    setIsVisible(true);

    // Sequential animation
    const timeoutLeft = setTimeout(() => {
      setHighlightLeft(true);
    }, 800);

    const timeoutRight = setTimeout(() => {
      setHighlightRight(true);
    }, 1200);

    // Handle window resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeoutLeft);
      clearTimeout(timeoutRight);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine if mobile mode
  const isMobile = windowWidth < 768;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
        padding: "20px",
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        transition: "opacity 0.8s ease",
        opacity: isVisible ? 1 : 0,
      }}
    >
      {/* Left Card - SANS Neuf Market */}
      <div
        style={{
          width: isMobile ? "100%" : "48%",
          backgroundColor: "#fff",
          borderRadius: "12px",
          padding: "32px",
          border: "1px solid #071c1f",
          boxShadow: highlightLeft ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "none",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          transform: highlightLeft ? "translateY(-4px)" : "translateY(0)",
          opacity: isVisible ? 1 : 0,
        }}
        onMouseEnter={() => setHighlightLeft(true)}
        onMouseLeave={() => setHighlightLeft(false)}
      >
        <div style={{ textAlign: "center" }}>
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#333",
              marginBottom: "12px",
            }}
          >
            SANS APPARTEMENT 9
          </h2>
        </div>

        <div style={{ marginTop: "32px" }}>
          {[
            "Multiples recherches pour dénicher des biens",
            "Données fragmentées et floues",
            "Comparaisons longues et fastidieuses",
            "Absence d'accompagnement pour appréhender le marché",
            "Choix difficiles sans outils efficaces de comparaison",
          ].map((text, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(10px)",
                transition: `opacity 0.5s ease ${
                  0.1 + index * 0.1
                }s, transform 0.5s ease ${0.1 + index * 0.1}s`,
              }}
            >
              <span
                style={{
                  color: "#e63946",
                  fontSize: "20px",
                  marginRight: "12px",
                  flexShrink: 0,
                }}
              >
                ✕
              </span>
              <p
                style={{
                  fontSize: "15px",
                  margin: 0,
                  color: "#555",
                  lineHeight: "1.4",
                  fontWeight: "bold", // Added bold font weight
                }}
              >
                {text}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Right Card - AVEC Neuf Market */}
      <div
        style={{
          width: isMobile ? "100%" : "48%",
          backgroundColor: "#fff",
          borderRadius: "12px",
          padding: "32px",
          border: "1px solid #304798",
          boxShadow: highlightRight
            ? "0 4px 12px rgba(99, 102, 241, 0.2)"
            : "none",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          transform: highlightRight ? "translateY(-4px)" : "translateY(0)",
          opacity: isVisible ? 1 : 0,
        }}
        onMouseEnter={() => setHighlightRight(true)}
        onMouseLeave={() => setHighlightRight(false)}
      >
        <div style={{ textAlign: "center" }}>
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#333",
              marginBottom: "12px",
            }}
          >
            AVEC APPARTEMENT 9
          </h2>
        </div>
        <div style={{ marginTop: "32px" }}>
          {[
            "Un conseiller unique et objectif : votre projet notre mission",
            "Prix promoteurs : transparence totale, aucune surprise!",
            "Accompagnement complet : on s'oupe de tout, vous profitez!",
            "Conseils d'experts : immobilier & financement sur mesure!",
            "Décisions éclairées : simulations précises, choix sécurisés !",
            "Avantages exclusifs: accès privilégié aux meilleures offres !",
            "Analyse du marché : données fiables, décisions avisées !",
            "Optimisation maximale : aides financières verifiées et garanties!",
            "Suivi jusqu'à la livraison : une expérience sans stress !",
          ].map((text, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(10px)",
                transition: `opacity 0.5s ease ${
                  0.1 + index * 0.1
                }s, transform 0.5s ease ${0.1 + index * 0.1}s`,
              }}
            >
              <span
                style={{
                  color: "#6366f1",
                  fontSize: "20px",
                  marginRight: "12px",
                  flexShrink: 0,
                }}
              >
                ✓
              </span>
              <p
                style={{
                  fontSize: "15px",
                  margin: 0,
                  color: "#555",
                  lineHeight: "1.4",
                  fontWeight: "bold", // Added bold font weight
                }}
              >
                {text}
              </p>
            </div>
          ))}

          <div
            style={{
              textAlign: "center",
              marginTop: "32px",
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0)" : "translateY(20px)",
              transition: "opacity 0.6s ease 1s, transform 0.6s ease 1s",
            }}
          >
            <a
              href="https://calendly.com/tony-appartement9/30min?month=2025-03"
              target="_blank"
            >
              <button
                style={{
                  backgroundColor: "#304798",
                  color: "#fff",
                  padding: "14px 24px",
                  borderRadius: "6px",
                  border: "none",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: "100%",
                  transition: "background-color 0.3s ease",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#304798";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#304798";
                }}
              >
                VOUS ÊTES PRÊT ? NOUS AUSSI. LANCEZ-VOUS !
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveAnimatedComparison;
