import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import BannerV6 from './section-components/banner-v6';
import Aboutv2 from './section-components/about-v2';
import Featuresv1 from './section-components/features-v1';
import ProductListingV1 from './section-components/product-listing';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import RollingGallery from './section-components/ClientSection';

const Home_V7 = () => {
    return (
        <div>
            <Helmet>
                <title>Appart9 | Expert Immobilier en Île-de-France - Achat et Vente d'Appartements</title>
                <meta name="description" content="Appart9, votre partenaire immobilier de confiance en Île-de-France. Trouvez l'appartement de vos rêves ou vendez votre bien avec notre expertise du marché local." />
                <meta name="keywords" content="Appart9, Immobilier Île-de-France, Achat appartement, Vente immobilier, Agence immobilière, Logement neuf, Investissement immobilier" />
                <meta property="og:title" content="Appart9 | Expert Immobilier en Île-de-France - Achat et Vente d'Appartements" />
                <meta property="og:description" content="Découvrez Appart9, votre expert immobilier en Île-de-France. Achat, vente, conseil - nous vous accompagnons dans tous vos projets immobiliers." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.appart9.com" />
                <meta property="og:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Appart9 | Expert Immobilier en Île-de-France - Achat et Vente d'Appartements" />
                <meta name="twitter:description" content="Découvrez Appart9, votre expert immobilier en Île-de-France. Achat, vente, conseil - nous vous accompagnons dans tous vos projets immobiliers." />
                <meta name="twitter:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
            </Helmet>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <BannerV6 />
            <Aboutv2 />
            <Featuresv1 customClass="ltn_feature-area section-bg-1 pt-30 pb-90 mb-120---" />
            <ProductListingV1 />
            <Testimonial />
            <RollingGallery autoplay={true} pauseOnHover={true} />
            <BlogSlider customClass="section-subtitle-2" />
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

export default Home_V7;