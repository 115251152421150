import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';

const Navbar = ({ CustomClass = '' }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const history = useHistory();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            setToken(savedToken);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken(null);
        history.push('/login');
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div>
            <header className={`ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4--- ${CustomClass}`}>
                <div className="ltn__header-top-area top-area-color-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="ltn__top-bar-menu">
                                    <ul>
                                        <li><a href="mailto:info@webmail.com?Subject=Flower%20greetings%20to%20you"><i className="icon-mail" /> info@Appartement9.com</a></li>
                                        <li><a href="locations.html"><i className="icon-placeholder" /> 15 Montmartre, Paris</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="top-bar-right text-end">
                                    <div className="ltn__top-bar-menu">
                                        <ul>
                                            <li>
                                                <Link to="/social"><i className="icon-social" /> Social Media</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="site-logo-wrap">
                                    <div className="d-none d-xl-block" style={{ width: "250px" }}>
                                        <Link to="/"><img src={`/assets/logo/logo.png`} alt="Logo" width={"125px"} style={{marginTop:'10px'}} /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col header-menu-column menu-color-white">
                                <div className="header-menu d-none d-xl-block go-top">
                                    <nav>
                                        <div className="ltn__main-menu">
                                            <ul>
                                                <li><Link to="/">Accueil</Link></li>
                                                <li><Link to="/about">À propos</Link></li>
                                                <li><Link to="/shop">Appartements</Link></li>
                                                <li><Link to="/blog-grid">Actualités</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>

                                                {token ? (
                                                    <li style={{ marginLeft: "150px" }} className="dropdown" onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
                                                        <Link to="#" className="dropdown-toggle">
                                                            Ajouter
                                                        </Link>
                                                        <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                                            <li>
                                                                <Link to="/dashboard-annonces">
                                                                    Gestion des annonces
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/dashboard-actualite">
                                                                    Gestion des actualités
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <button onClick={handleLogout} className="dropdown-item" style={{ border: 'none', background: 'none', padding: 0 }}>
                                                                    <LogoutOutlined style={{ marginRight: '8px' }} />
                                                                    Se déconnecter
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="col--- ltn__header-options ltn__header-options-2">
                                <div className="mobile-menu-toggle d-xl-none">
                                    <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle" onClick={(e) => {
                                        e.preventDefault();
                                        toggleMobileMenu();
                                    }}>
                                        <svg viewBox="0 0 800 600">
                                            <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
                                            <path d="M300,320 L540,320" id="middle" />
                                            <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318)" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile Menu */}
                <div className={`ltn__utilize ltn__utilize-mobile-menu ${isMobileMenuOpen ? 'ltn__utilize-open' : ''}`}>
                    <div className="ltn__utilize-menu-inner ltn__scrollbar">
                        <div className="ltn__utilize-menu-head">
                            <div className="site-logo">
                                <Link to="/">
                                    <img src="/assets/logo/logo.png" alt="Logo" className="w-32" />
                                </Link>
                            </div>
                            <button 
                                className="ltn__utilize-close" 
                                onClick={toggleMobileMenu}
                                aria-label="Close mobile menu"
                            >
                                ×
                            </button>
                        </div>
                        <div className="ltn__utilize-menu">
                            <ul>
                                <li><Link to="/" onClick={toggleMobileMenu}>Accueil</Link></li>
                                <li><Link to="/about" onClick={toggleMobileMenu}>À propos</Link></li>
                                <li><Link to="/shop" onClick={toggleMobileMenu}>Appartements</Link></li>
                                <li><Link to="/blog-grid" onClick={toggleMobileMenu}>Actualités</Link></li>
                                <li><Link to="/contact" onClick={toggleMobileMenu}>Contact</Link></li>
                                
                                {token && (
                                    <>
                                        <li><Link to="/dashboard-annonces" onClick={toggleMobileMenu}>Gestion des annonces</Link></li>
                                        <li><Link to="/dashboard-actualite" onClick={toggleMobileMenu}>Gestion des actualités</Link></li>
                                        <li>
                                            <button
                                                onClick={() => {
                                                    handleLogout();
                                                    toggleMobileMenu();
                                                }}
                                                className="flex items-center w-full border-0 bg-transparent p-0"
                                            >
                                                <LogoutOutlined style={{ marginRight: '8px' }} />
                                                Se déconnecter
                                            </button>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Navbar;