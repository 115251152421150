import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import Features from './section-components/features-v1';
import Team from './section-components/team-v1';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import FeaturesV2 from './section-components/Features-v2';
import Services from './section-components/service-v1';

const About_v1 = () => {
    return (
        <div>
            <Helmet>
                <title>À Propos | Appart9 - Expert en immobilier neuf en Île-de-France</title>
                <meta name="description" content="Découvrez Appart9, votre partenaire de confiance pour l'achat d'appartements neufs en Île-de-France. Notre équipe d'experts vous accompagne dans votre projet immobilier." />
                <meta name="keywords" content="Appart9, Immobilier neuf Île-de-France, Achat appartement Paris, Logement neuf région parisienne, Expert immobilier, Projet immobilier, Appartement T2 T3 Paris, Investissement immobilier" />
                <meta property="og:title" content="À Propos | Appart9 - Expert en immobilier neuf en Île-de-France" />
                <meta property="og:description" content="Découvrez Appart9, votre partenaire de confiance pour l'achat d'appartements neufs en Île-de-France. Notre équipe d'experts vous accompagne dans votre projet immobilier." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.appart9.com/a-propos" />
                <meta property="og:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="À Propos | Appart9 - Expert en immobilier neuf en Île-de-France" />
                <meta name="twitter:description" content="Découvrez Appart9, votre partenaire de confiance pour l'achat d'appartements neufs en Île-de-France. Notre équipe d'experts vous accompagne dans votre projet immobilier." />
                <meta name="twitter:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
            </Helmet>
            <Navbar />
            <PageHeader headertitle="À Propos de nous" />
            <AboutV4 />
            {/* <Features customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" /> */}
            <Services />
            <FeaturesV2 customClass="ltn__feature-area section-bg-1 pb-90 mb-120---" />
            {/* <Team /> */}
            {/* <Testimonial /> */}
            <BlogSlider />
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

export default About_v1;