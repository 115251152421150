import React, { useEffect, useState } from "react";

import { BASE_URL } from "../../constants/Config";
import MapSelect from "./MapSelect";
import Select from "react-select";
import { actCreateAppartement } from "../../slices/appartement/AppartSlice";
import axios from "axios";
import { useDispatch } from "react-redux";

// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

const AddListing = () => {
    const dispatch = useDispatch();
    const [longitude, setLongitude] = useState("");
    const [latitude, setLatitude] = useState("");
    const [metros, setMetros] = useState([]);
    const [tramways, setTramways] = useState([]);
    const [rers, setRers] = useState([]);
    const [errors, setErrors] = useState({});
    const [submitStatus, setSubmitStatus] = useState(null);

    const requiredFields = [
        "title",
        "description",
        "price",
        // "location",
        // "longitude",
        // "latitude",
        "surface",
        "rooms",
        "category",
        "type",
        "status",
        "departement",
        "ville",
        "pays",
        "adress",
        "codePostal",
        "image",
        "motA9",
    ];

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        price: "",
        priceLabel: "",
        beforePriceLabel: "",
        taxRate: "",
        hoaFee: "",
        category: "",
        type: "",
        status: "",
        adress: "",
        ville: "",
        pays: "",
        quartier: "",
        codePostal: "",
        longitude: "",
        latitude: "",
        surface: "",
        lotSize: "",
        rooms: "",
        bedrooms: "",
        bathrooms: "",
        customId: "",
        garages: "",
        yearBuilt: "",
        garageSize: "",
        availableFrom: "",
        basement: "",
        extraDetails: "",
        roofing: "",
        exteriorMaterial: "",
        structure: "",
        floorsNo: "",
        notes: "",
        energyClass: "",
        energyIndex: "",
        interiorEquipments: [],
        exteriorEquipments: [],
        caracteristics: [],
        equipments: [],
        Cuisine: [],
        Connectivité: [],
        image: [],
        gallery: [],
        video: "",
        virtualTour: "",
        metro: [],
        tramway: [],
        rer: [],
        motA9: "",
        floorPlans: [],
    });

    useEffect(() => {
        async function getMetros() {
            try {
                const response = await axios.get(`${BASE_URL}/metros`);
                setMetros(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        async function getTramways() {
            try {
                const response = await axios.get(`${BASE_URL}/trams`);
                setTramways(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        async function getRers() {
            try {
                const response = await axios.get(`${BASE_URL}/rer`);
                setRers(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getMetros();
        getTramways();
        getRers();

        return () => {
            setMetros([]);
            setTramways([]);
            setRers([]);
        };
    }, []);

    const category = [
        { value: "Appartement", label: "Appartement" },
        { value: "Maison", label: "Maison" },
    ];

    const type = [
        // { value: 'Location', label: 'Location' },
        { value: "Vente", label: "Vente" },
    ];

    const status = [
        { value: "Actif", label: "Actif" },
        { value: "Offre chaude", label: "Offre chaude" },
        { value: "Nouvelle offre", label: "Nouvelle offre" },
        { value: "Portes ouvertes", label: "Portes ouvertes" },
        { value: "Vendu", label: "Vendu" },
    ];

    const structure = [
        { value: "Non disponible", label: "Non disponible" },
        { value: "Brique", label: "Brique" },
        { value: "Bois", label: "Bois" },
        { value: "Ciment", label: "Ciment" },
    ];

    const floorsNo = [
        { value: "Non disponible", label: "Non disponible" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
    ];

    const energyClass = [
        { value: "A+", label: "A+" },
        { value: "A", label: "A" },
        { value: "B", label: "B" },
        { value: "C", label: "C" },
        { value: "D", label: "D" },
        { value: "E", label: "E" },
    ];

    const department = [
        { value: "Paris", label: "Paris (75)" },
        { value: "Seine-et-Marne", label: "Seine-et-Marne (77)" },
        { value: "Yvelines", label: "Yvelines (78)" },
        { value: "Essonne", label: "Essonne (91)" },
        { value: "Hauts-de-Seine", label: "Hauts-de-Seine (92)" },
        { value: "Seine-Saint-Denis", label: "Seine-Saint-Denis (93)" },
        { value: "Val-de-Marne", label: "Val-de-Marne (94)" },
        { value: "Val-d-Oise", label: "Val d-Oise (95)" },
    ];

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === "file") {
            if (name === "image") {
                setFormData((prevState) => ({
                    ...prevState,
                    image: Array.from(files),
                }));
            } else if (name === "gallery") {
                setFormData((prevState) => ({
                    ...prevState,
                    gallery: Array.from(files),
                }));
            } else if (name === "floorPlan") {
                setFormData((prevState) => ({
                    ...prevState,
                    floorPlans: Array.from(files),
                }));
            }
        } else if (type === "checkbox") {
            setFormData((prevState) => {
                const arrayToUpdate = prevState[name];
                if (checked) {
                    return {
                        ...prevState,
                        [name]: [...arrayToUpdate, value],
                    };
                } else {
                    return {
                        ...prevState,
                        [name]: arrayToUpdate.filter((item) => item !== value),
                    };
                }
            });
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

        // Clear error when user starts typing
        if (errors[name]) {
            setErrors((prev) => ({
                ...prev,
                [name]: "",
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            setSubmitStatus("error");
            return;
        }

        try {
            const data = new FormData();

            // Append all form data to FormData
            Object.keys(formData).forEach((key) => {
                if (key === "image" || key === "gallery") {
                    formData[key].forEach((file) => {
                        data.append(key, file);
                    });
                } else if (key === "floorPlans") {
                    formData[key].forEach((file) => {
                        data.append("floorPlan", file);
                    });
                } else if (Array.isArray(formData[key])) {
                    formData[key].forEach((value) => {
                        data.append(key, value);
                    });
                } else {
                    data.append(key, formData[key]);
                }
            });

            dispatch(actCreateAppartement(data));
            setSubmitStatus("success");

            // Reset form after successful submission
            setFormData({
                title: "",
                description: "",
                price: "",
                priceLabel: "",
                beforePriceLabel: "",
                taxRate: "",
                hoaFee: "",
                category: "",
                type: "",
                status: "",
                adress: "",
                ville: "",
                pays: "",
                quartier: "",
                codePostal: "",
                longitude: "",
                latitude: "",
                surface: "",
                lotSize: "",
                rooms: "",
                bedrooms: "",
                bathrooms: "",
                customId: "",
                garages: "",
                yearBuilt: "",
                garageSize: "",
                availableFrom: "",
                basement: "",
                extraDetails: "",
                roofing: "",
                exteriorMaterial: "",
                structure: "",
                floorsNo: "",
                notes: "",
                energyClass: "",
                energyIndex: "",
                interiorEquipments: [],
                exteriorEquipments: [],
                caracteristics: [],
                equipments: [],
                Cuisine: [],
                Connectivité: [],
                image: [],
                gallery: [],
                video: "",
                virtualTour: "",
                metro: [],
                tramway: [],
                rer: [],
                motA9: "",
                floorPlans: [],
            });
        } catch (error) {
            setSubmitStatus("error");
            console.error("Submission error:", error);
        }
    };

    const formStyles = {
        errorInput: {
            border: '1px solid #dc3545',
            backgroundColor: '#fff8f8'
        },
        errorMessage: {
            color: '#dc3545',
            fontSize: '12px',
            marginTop: '4px',
            display: 'block',
            position: 'relative',
            paddingLeft: '5px'
        },
        inputItem: {
            position: 'relative',
            marginBottom: '20px'
        },
        requiredField: {
            color: '#dc3545',
            marginLeft: '4px'
        },
        selectError: {
            border: '1px solid #dc3545',
            backgroundColor: '#fff8f8'
        }
    };

    const handleMapChange = (long, lat) => {
        setFormData((prevState) => ({
            ...prevState,
            longitude: long.toString(),
            latitude: lat.toString(),
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = "Ce champ est obligatoire";
            }
        });

        // Additional validation
        if (formData.price && isNaN(formData.price)) {
            newErrors.price = "Le prix doit être un nombre";
        }
        if (formData.surface && isNaN(formData.surface)) {
            newErrors.surface = "La surface doit être un nombre";
        }
        if (formData.rooms && isNaN(formData.rooms)) {
            newErrors.rooms = "Le nombre de pièces doit être un nombre";
        }
        if (formData.image && formData.image.length === 0) {
            newErrors.image = "Au moins une image est requise";
        }

        // Optional: Add file size validation
        if (formData.image) {
            const maxSize = 5 * 1024 * 1024; // 5MB
            for (let file of formData.image) {
                if (file.size > maxSize) {
                    newErrors.image = "Les fichiers doivent être inférieurs à 5MB";
                    break;
                }
            }
        }

        if (formData.gallery) {
            const maxSize = 5 * 1024 * 1024; // 5MB
            for (let file of formData.gallery) {
                if (file.size > maxSize) {
                    newErrors.gallery = "Les fichiers doivent être inférieurs à 5MB";
                    break;
                }
            }
        }

        if (formData.floorPlans) {
            const maxSize = 5 * 1024 * 1024; // 5MB
            for (let file of formData.floorPlans) {
                if (file.size > maxSize) {
                    newErrors.floorPlan = "Les fichiers doivent être inférieurs à 5MB";
                    break;
                }
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // let publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div className="ltn__appointment-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__appointment-inner">
                            {submitStatus && (
                                <div
                                    className={`alert ${submitStatus === "success"
                                        ? "alert-success"
                                        : "alert-danger"
                                        } mb-4`}
                                >
                                    {submitStatus === "success"
                                        ? "Propriété listée avec succès !"
                                        : "Veuillez vérifier tous les champs obligatoires et réessayer."}
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <h2>1. Description <span style={formStyles.requiredField}>*</span></h2>
                                {/* <p>
                                    <small>
                                        Ces champs sont obligatoires : Titre, Médias de la propriété
                                    </small>
                                </p> */}
                                <h6>Description de la propriété</h6>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="title"
                                                value={formData.title}
                                                onChange={handleChange}
                                                placeholder="*Titre (obligatoire)"
                                                style={errors.title ? formStyles.errorInput : {}}
                                            />
                                            {/* {errors.title && (
                                                <div style={formStyles.errorMessage}>{errors.title}</div>
                                            )} */}
                                        </div>
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="motA9"
                                                value={formData.motA9}
                                                onChange={handleChange}
                                                placeholder="*Mot A9 (obligatoire)"
                                                style={errors.motA9 ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="*Description"
                                                style={errors.description ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h6>Prix de la propriété</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="price"
                                                value={formData.price}
                                                onChange={handleChange}
                                                placeholder="*Prix en € (seulement des chiffres)"
                                                style={errors.price ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="priceLabel"
                                                value={formData.priceLabel}
                                                onChange={handleChange}
                                                placeholder="Libellé après le prix (ex: /mois)"
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="beforePriceLabel"
                                                value={formData.beforePriceLabel}
                                                onChange={handleChange}
                                                placeholder="Libellé avant le prix (ex: à partir de)"
                                                style={errors.beforePriceLabel ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="taxRate"
                                                value={formData.taxRate}
                                                onChange={handleChange}
                                                placeholder="Taux d'imposition annuel"
                                                style={errors.taxRate ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="hoaFee"
                                                value={formData.hoaFee}
                                                onChange={handleChange}
                                                placeholder="Frais de copropriété (mensuel)"
                                                style={errors.hoaFee ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h6>Select Categories*</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-item" style={formStyles.inputItem}>
                                            <Select
                                                options={category}
                                                value={category.find((option) => option.value === formData.category)}
                                                placeholder="*Selectionnez la category"
                                                onChange={(selectedOption) => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        category: selectedOption.value,
                                                    }));
                                                }}
                                                styles={errors.category ? {
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        ...formStyles.errorInput
                                                    })
                                                } : {}}
                                            />
                                            {errors.category && (
                                                <div style={formStyles.errorMessage}>{errors.category}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={type}
                                                value={type.find(
                                                    (option) => option.value === formData.type
                                                )}
                                                placeholder="*Selectionnez le type"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        type: selectedOption.value,
                                                    }));
                                                }}
                                                styles={errors.type ? {
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        ...formStyles.errorInput
                                                    })
                                                } : {}}
                                            />
                                            {errors.type && (
                                                <div style={formStyles.errorMessage}>{errors.type}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={status}
                                                value={status.find(
                                                    (option) => option.value === formData.status
                                                )}
                                                placeholder="*Selectionnez le Status"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        status: selectedOption.value,
                                                    }));
                                                }}
                                                styles={errors.status ? {
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        ...formStyles.errorInput
                                                    })
                                                } : {}}
                                            />
                                            {errors.status && (
                                                <div style={formStyles.errorMessage}>{errors.status}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h2>2. Médias (obligatoires)<span style={formStyles.requiredField}>*</span></h2>
                                <h6>Médias de l'annonce*</h6>
                                <div className="input-item" style={formStyles.inputItem}>
                                    <input
                                        type="file"
                                        id="propertyFiles"
                                        name="image"
                                        multiple
                                        onChange={handleChange}
                                        className={`btn theme-btn-3 mb-10 ${errors.image ? 'error-input' : ''}`}
                                        style={errors.image ? formStyles.errorInput : {}}
                                    />
                                    {errors.image && (
                                        <div style={formStyles.errorMessage}>{errors.image}</div>
                                    )}
                                    <br />
                                    <p>
                                        <small>
                                            * Au moins 1 image est requise pour une soumission valide.
                                            Taille minimale : 500/500px.
                                        </small>
                                        <br />
                                        <small>
                                            * Les fichiers PDF sont également pris en charge.
                                        </small>
                                        <br />
                                        <small>
                                            * Le traitement des images peut prendre un certain temps.
                                        </small>
                                    </p>
                                </div>

                                <h6>Galerie d'images*</h6>
                                <div className="input-item" style={formStyles.inputItem}>
                                    <input
                                        type="file"
                                        id="galleryFiles"
                                        name="gallery"
                                        multiple
                                        onChange={handleChange}
                                        className={`btn theme-btn-3 mb-10 ${errors.gallery ? 'error-input' : ''}`}
                                        style={errors.gallery ? formStyles.errorInput : {}}
                                    />
                                    {errors.gallery && (
                                        <div style={formStyles.errorMessage}>{errors.gallery}</div>
                                    )}
                                    <br />
                                    <p>
                                        <small>
                                            * Vous pouvez ajouter jusqu'à 10 images à la galerie.
                                        </small>
                                        <br />
                                        <small>* Taille maximale par fichier : 5MB.</small>
                                        <br />
                                        <small>
                                            * Le traitement des images peut prendre un certain temps.
                                        </small>
                                    </p>
                                </div>
                                <h6>Plan de l'appartement*</h6>
                                <div className="input-item" style={formStyles.inputItem}>
                                    <input
                                        type="file"
                                        id="floorPlanFiles"
                                        name="floorPlan"
                                        multiple
                                        onChange={handleChange}
                                        className={`btn theme-btn-3 mb-10 ${errors.floorPlan ? 'error-input' : ''}`}
                                        style={errors.floorPlan ? formStyles.errorInput : {}}
                                    />
                                    {errors.floorPlan && (
                                        <div style={formStyles.errorMessage}>{errors.floorPlan}</div>
                                    )}
                                    <br />
                                    <p>
                                        <small>
                                            * Vous pouvez ajouter jusqu'à 3 plans d'étage.
                                        </small>
                                        <br />
                                        <small>* Taille maximale par fichier : 5MB.</small>
                                        <br />
                                        <small>
                                            * Formats acceptés : JPG, PNG, PDF.
                                        </small>
                                    </p>
                                </div>
                                <h6>Option vidéo</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <select className="nice-select">
                                                <option>Vidéo de</option>
                                                {/* <option>Vimeo</option> */}
                                                <option>YouTube</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="ltn__name"
                                                placeholder="ID de la vidéo intégrée"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h6>Visite virtuelle</h6>
                                <div className="input-item input-item-textarea ltn__custom-icon">
                                    <textarea
                                        name="ltn__message"
                                        placeholder="Visite virtuelle :"
                                        defaultValue={""}
                                    />
                                </div>
                                <h2>3. Localisation <span style={formStyles.requiredField}>*</span></h2>
                                <h6>Emplacement de l'annonce</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="adress"
                                                placeholder="*Adresse"
                                                value={formData.adress}
                                                onChange={handleChange}
                                                style={errors.adress ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="pays"
                                                placeholder="*Pays"
                                                value={formData.pays}
                                                onChange={handleChange}
                                                style={errors.pays ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <Select
                                                options={department}
                                                value={department.find(
                                                    (option) => option.value === formData.departement
                                                )}
                                                placeholder="*Selectionnez le département/la région"
                                                onChange={(selectedOption) => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        departement: selectedOption.value,
                                                    }));
                                                }}
                                                styles={errors.departement ? {
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        ...formStyles.errorInput
                                                    })
                                                } : {}}
                                            />
                                            {errors.departement && (
                                                <div style={formStyles.errorMessage}>{errors.departement}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="ville"
                                                placeholder="*Ville"
                                                value={formData.ville}
                                                onChange={handleChange}
                                                style={errors.ville ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="quartier"
                                                placeholder="Quartier"
                                                value={formData.quartier}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="codePostal"
                                                placeholder="*Code postal"
                                                value={formData.codePostal}
                                                onChange={handleChange}
                                                style={errors.codePostal ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="property-details-google-map mb-60">
                                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} title='unique' /> */}
                                            <MapSelect
                                                longitude={formData.longitude}
                                                latitude={formData.latitude}
                                                onPositionChange={handleMapChange}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="latitude"
                                                placeholder="Latitude (pour Google Maps)"
                                                onChange={handleChange}
                                                value={latitude}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="longitude"
                                                placeholder="Longitude (pour Google Maps)"
                                                onChange={handleChange}
                                                value={longitude}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="checkbox-item">
                                            Activer Google Street View
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="ltn__name"
                                                placeholder="Google Street View - Angle de caméra (valeur de 0 à 360)"
                                            />
                                        </div>
                                    </div> */}
                                </div>
                                <h2>4. Détails <span style={formStyles.requiredField}>*</span></h2>
                                <h6>Détails de l'annonce</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="surface"
                                                value={formData.surface}
                                                onChange={handleChange}
                                                placeholder="*Taille en m² (seulement des chiffres)"
                                                style={errors.surface ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="lotSize"
                                                value={formData.lotSize}
                                                onChange={handleChange}
                                                placeholder="Taille du terrain en m² (seulement des chiffres)"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon" style={formStyles.inputItem}>
                                            <input
                                                type="text"
                                                name="rooms"
                                                value={formData.rooms}
                                                onChange={handleChange}
                                                placeholder="*Pièces (seulement des chiffres)"
                                                style={errors.rooms ? formStyles.errorInput : {}}
                                            />
                                        </div>
                                    </div>

                                    {/* The rest of the non-required fields */}
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="bedrooms"
                                                value={formData.bedrooms}
                                                onChange={handleChange}
                                                placeholder="Chambres (seulement des chiffres)"
                                            />
                                        </div>
                                    </div>
                                    {/* Continue with the rest of your fields without error handling since they're not required */}

                                    {/* For Select components */}
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={structure}
                                                value={structure.find(
                                                    (option) => option.value === formData.structure
                                                )}
                                                placeholder="Type de structure"
                                                onChange={(selectedOption) => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        structure: selectedOption.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={floorsNo}
                                                value={floorsNo.find(
                                                    (option) => option.value === formData.floorsNo
                                                )}
                                                placeholder="Nombre d'étages"
                                                onChange={(selectedOption) => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        floorsNo: selectedOption.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* The textarea with error handling if notes are required */}
                                    <div className="col-lg-12">
                                        <br />
                                        <div className="input-item input-item-textarea ltn__custom-icon" style={formStyles.inputItem}>
                                            <textarea
                                                name="notes"
                                                value={formData.notes}
                                                onChange={handleChange}
                                                placeholder="Notes du propriétaire/agent (non visibles sur le site)"
                                                style={errors.notes ? formStyles.errorInput : {}}
                                            />
                                            {errors.notes && (
                                                <div style={formStyles.errorMessage}>{errors.notes}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <h6>Sélectionner la classe énergétique</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={energyClass}
                                                value={energyClass.find(
                                                    (option) => option.value === formData.energyClass
                                                )}
                                                placeholder="Sélectionner la classe énergétique (réglementation UE)"
                                                onChange={(selectedOption) => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        energyClass: selectedOption.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="energyIndex"
                                                value={formData.energyIndex}
                                                onChange={handleChange}
                                                placeholder="Indice énergétique en kWh/m²a"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h2>5. Équipements</h2>
                                <h6>Équipements et caractéristiques</h6>
                                <h6>Sécurité & accès</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Vidéophone / interphone
                                            <input
                                                type="checkbox"
                                                name="interiorEquipments"
                                                value="Vidéophone / interphone"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Badge Vigik et digicode
                                            <input
                                                type="checkbox"
                                                name="interiorEquipments"
                                                value="Badge Vigik et digicode"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Portail automatique pour le parking
                                            <input
                                                type="checkbox"
                                                name="interiorEquipments"
                                                value="Portail automatique pour le parking"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>
                                <h6 className="mt-20">Espaces communs</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Local à vélos et poussettes
                                            <input
                                                type="checkbox"
                                                name="exteriorEquipments"
                                                value="Local à vélos et poussettes"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Ascenseur
                                            <input
                                                type="checkbox"
                                                name="exteriorEquipments"
                                                value="Ascenseur"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Espaces verts / jardin paysager
                                            <input
                                                type="checkbox"
                                                name="exteriorEquipments"
                                                value="Espaces verts / jardin paysager"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h6 className="mt-20">Stationnement & mobilité :</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Parking en sous-sol ou aérien
                                            <input
                                                type="checkbox"
                                                name="equipments"
                                                value="Parking en sous-sol ou aérien"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h6 className="mt-20">Confort & économie d’énergie :</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Chauffage individuel (gaz, pompe à chaleur, ou électrique
                                            selon le projet)
                                            <input
                                                type="checkbox"
                                                name="caracteristics"
                                                value="Chauffage individuel (gaz, pompe à chaleur, ou électrique selon le projet)"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Double vitrage et isolation renforcée (RT 2012 ou RE 2020)
                                            <input
                                                type="checkbox"
                                                name="caracteristics"
                                                value="Double vitrage et isolation renforcée (RT 2012 ou RE 2020)"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Volets roulants électriques
                                            <input
                                                type="checkbox"
                                                name="caracteristics"
                                                value="Volets roulants électriques"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h6 className="mt-20">Cuisine & salle de bain :</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Salle de bain avec meuble vasque, miroir et
                                            sèche-serviette
                                            <input
                                                type="checkbox"
                                                name="Cuisine"
                                                value="Salle de bain avec meuble vasque, miroir et sèche-serviette"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h6 className="mt-20">
                                    Connectivité & domotique (selon le promoteur) :
                                </h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">
                                            Fibre optique
                                            <input
                                                type="checkbox"
                                                name="Connectivité"
                                                value="Fibre optique"
                                                onChange={handleChange}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h2>Transport</h2>
                                <h6>Lignes metro</h6>
                                <div className="row">
                                    {metros.Metros?.map((metro, index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <label className="checkbox-item">
                                                Ligne {metro.line}
                                                <input
                                                    type="checkbox"
                                                    name="metro"
                                                    value={metro._id}
                                                    onChange={handleChange}
                                                />
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <h6 className="mt-20">Lignes RER</h6>
                                <div className="row">
                                    {rers.Rers?.map((rer, index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <label className="checkbox-item">
                                                Ligne {rer.line}
                                                <input
                                                    type="checkbox"
                                                    name="rer"
                                                    value={rer._id}
                                                    onChange={handleChange}
                                                />
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <h6 className="mt-20">Lignes Tramway</h6>
                                <div className="row">
                                    {tramways.Trams?.map((tramway, index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <label className="checkbox-item">
                                                Ligne {tramway.line}
                                                <input
                                                    type="checkbox"
                                                    name="tramway"
                                                    value={tramway._id}
                                                    onChange={handleChange}
                                                />
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="alert alert-warning d-none" role="alert">
                                    Veuillez noter que la date et l'heure que vous avez demandées
                                    peuvent ne pas être disponibles. Nous vous contacterons pour
                                    confirmer les détails réels de votre rendez-vous.
                                </div>
                                <div className="btn-wrapper text-center mt-30">
                                    <button
                                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                        type="submit"
                                    >
                                        Soumettre la propriété
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default AddListing;
