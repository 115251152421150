import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl + "assets/img/others/abt 2.png"} alt="About Us Image" />
							<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
								<div className="ltn__video-img ltn__animation-pulse1">
									{/* <img src={publicUrl+"assets/img/others/8.png"} alt="video popup bg image" />
							<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
							</a> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">À Propos de nous</h6>
								<h1 className="text-left text-3xl font-bold text-gray-900">L’EXPERIENCE A VOTRE SERVICE POUR UN ACCOMPAGNEMENT SUR MESURE<span>.</span></h1>
								<p>Après plus de 25 ans d’expérience au sein des plus grands promoteurs immobiliers, j’ai fondé APPARTEMENT 9 pour offrir un service de vente d’appartements neufs sur plan qui place l’expertise et l’accompagnement personnalisé au cœur de chaque projet.<br/>
								Mon parcours m’a permis de développer une connaissance approfondie du marché immobilier et de vos attentes, que vous soyez primo-accédants ou investisseurs. Après avoir supervisé des centaines de projets pour des leaders du secteur, je mets aujourd’hui cette expérience à votre service, avec l’objectif de vous proposer des opportunités uniques et personnalisées dans le cadre de votre achat immobilier.
								</p>
							</div>

							<div className="ltn__callout bg-overlay-theme-05  mt-30">
								<p ><strong style={{ fontSize: '20px', fontWeight: 'bold' }}>Notre engagement</strong> <br />

								Vous accompagner avec attention et expertise afin de simplifier chaque étape de votre projet, prendre en compte vos critères spécifiques, vos priorités et votre mode de vie pour vous proposer une solution personnalisée. </p>
							</div>

							<div className="ltn__callout bg-overlay-theme-05  mt-30">
								<p ><strong style={{ fontSize: '20px', fontWeight: 'bold' }}>Notre vision</strong> <br />
                     Vous apporter une approche humaine et bienveillante, Nous croyons en des valeurs d’écoute, d’empathie et de transparence 
					 à chaque étape de votre projet de la recherche du bien, au financement de votre projet et jusqu'à la livraison  de votre chez vous
                     pour une experience sereine et réussie.  </p>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV4