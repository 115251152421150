import React, { Component, createRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
 
class CaracteristiquesV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            visibleCards: this.getInitialVisibleCards(),
            autoSliding: true,
            totalCards: 7
        };
        this.cardsContainerRef = createRef();
        this.autoSlideInterval = null;
        this.resizeTimeout = null;
    }
 
    getInitialVisibleCards() {
        if (window.innerWidth <= 768) {
            return 1;
        } else if (window.innerWidth <= 992) {
            return 2;
        } else {
            return 3;
        }
    }
 
    componentDidMount() {
        AOS.init({ duration: 1000, once: false });
        this.startAutoSlide();
        window.addEventListener('resize', this.handleResize);
    }
   
    componentWillUnmount() {
        this.stopAutoSlide();
        window.removeEventListener('resize', this.handleResize);
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
    }

    handleResize = () => {
        // Debounce resize event
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        
        this.resizeTimeout = setTimeout(() => {
            const newVisibleCards = this.getInitialVisibleCards();
            if (newVisibleCards !== this.state.visibleCards) {
                // Adjust active index if needed when resizing
                const maxActiveIndex = this.state.totalCards - newVisibleCards;
                const newActiveIndex = Math.min(this.state.activeIndex, maxActiveIndex);
                
                this.setState({
                    visibleCards: newVisibleCards,
                    activeIndex: newActiveIndex
                });
            }
        }, 200);
    }
 
    startAutoSlide = () => {
        this.autoSlideInterval = setInterval(() => {
            if (this.state.autoSliding) {
                const nextIndex = (this.state.activeIndex + 1) % (this.state.totalCards - this.state.visibleCards + 1);
                this.handleScroll(nextIndex);
            }
        }, 4000);
    }
 
    stopAutoSlide = () => {
        clearInterval(this.autoSlideInterval);    
    }
 
    handleScroll = (index) => {
        this.setState({ activeIndex: index });
    };
 
    handleCardClick = () => {
        this.setState({ autoSliding: false });
        this.stopAutoSlide();
    };

    handleCardMouseEnter = () => {
        this.setState({ autoSliding: false });
        this.stopAutoSlide();
    };

    handleCardMouseLeave = () => {
        this.setState({ autoSliding: true });
        this.startAutoSlide();
    };
 
    resetAutoSlide = () => {
        this.setState({ autoSliding: true });
        this.stopAutoSlide();
        this.startAutoSlide();
    };
 
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        const services = [
            { number: "1", video: "vid1.mp4", title: "Étude de vos besoins", description: "Nous prenons le temps de comprendre vos besoins, vos attentes et vos aspirations. Notre objectif ? Vous guider vers le bien qui correspond parfaitement à vos attentes. "},
            { number: "2", video: "vid2.mp4", title: "Étude financières complètes", description: "Nous réalisons une étude financière complète pour évaluer votre capacité d'achat et vous proposer les meilleures options. Notre objectif ? Vous offrir une vision claire de votre projet et vous guider vers les meilleures décisions. " },
            { number: "3", video: "vid3.mp4", title: "Présentation immobilière", description: "Nous vous présentons une sélection d'appartements neufs, mise à jour en temps réel Notre objectif ? Vous faire gagner du temps et vous offrir une expérience immobilière sereine et personnalisée." },
            { number: "4", video: "vid4.mp4", title: "Visite des terrains sélectionnés", description: "Une fois que nous avons sélectionné plusieurs biens, nous organisons des visites de terrain Lors de ces visites. Notre objectif ? Vous offrir une expérience immersive et vous aider à choisir le bien qui correspond parfaitement à vos attentes." },
            { number: "5", video: "vid5.mp4", title: "Signature du contrat", description: "Nous vous accompagnons lors de la signature du contrat pour garantir un processus clair, transparent et sécurisé. Notre objectif ? Vous offrir une expérience fluide et sécurisée, pour que vous puissiez signer en toute confiance." },
            { number: "6", video: "vid6.mp4", title: "Recherche du financement", description: "Nous vous aidons à évaluer votre capacité d'emprunt, comparer les offres de financement, identifier les aides disponibles et préparer votre dossier. Notre mission? Vous simplifier les démarches et vous offrir une expérience immobilière sereine et personnalisée" },
            { number: "7", video: "vid7.mp4", title: "Accompagnement livraison", description: "Nous vous accompagnons tout au long des tapes pour garantir un processus fluide et transparent. Notre objectif? Vous offrir une expérience sereine et vous garantir que votre nouvel appartement est prêt à accueillir vos rêves" }
        ];
 
        const transformValue = `translateX(-${this.state.activeIndex * (100 / this.state.visibleCards)}%)`;
 
        return (
            <div className="container">
                <h1 className="section-title mt-10">Nos engagements à vos côtés</h1>
                <div className="container">
                    <div ref={this.cardsContainerRef} className="cards-container">
                        <div className="cards-wrapper" style={{transform: transformValue}}>
                            {services.map((service, index) => (
                                <div
                                    key={index}
                                    className="custom-card"
                                    data-aos="fade-up"
                                    data-aos-delay={index * 100}
                                    onClick={this.handleCardClick}
                                    onMouseEnter={this.handleCardMouseEnter}
                                    onMouseLeave={this.handleCardMouseLeave}
                                >
                                    <div className="card-content ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1">
                                        <div className="image-container">
                                            <video autoPlay muted loop playsInline className="card-video">
                                                <source src={publicUrl + "assets/media/" + service.video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div className="ltn__feature-info">
                                            <h3 style={{ marginBottom: '10px' }}>{service.number}. {service.title}</h3>
                                            <div className="description-container">
                                                <p>{service.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="scroll-buttons">
                        {[...Array(this.state.totalCards - this.state.visibleCards + 1)].map((_, idx) => (
                            <button
                                key={idx}
                                onClick={() => {
                                    this.handleScroll(idx);
                                    this.resetAutoSlide();
                                }}
                                className={`scroll-btn ${this.state.activeIndex === idx ? 'active' : ''}`}
                            ></button>
                        ))}
                    </div>
               
                    <style>
                        {`
                        .cards-container {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            overflow: hidden;
                            padding: 0 15px;
                        }
                        .cards-wrapper {
                            display: flex;
                            width: 100%;
                            transition: transform 0.3s ease-in-out;
                        }
                        .custom-card {
                            width: calc(100% / ${this.state.visibleCards});
                            min-width: calc(100% / ${this.state.visibleCards});
                            padding: 0 10px;
                            box-sizing: border-box;
                            margin: 0 5px;
                            flex: 0 0 auto;
                            cursor: pointer;
                            perspective: 1000px;
                            transition: transform 0.5s ease;
                        }
                        .card-content {
                            background: white;
                            border-radius: 12px;
                            overflow: hidden;
                            transition: all 0.5s ease;
                            height: 100%;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            padding: 0;
                        }
                        .custom-card:hover .card-content {
                            transform: scale(1.05);
                            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
                            z-index: 10;
                        }
                        .image-container {
                            width: 100%;
                            height: 0;
                            padding-bottom: 56.25%; /* 16:9 aspect ratio */
                            position: relative;
                            overflow: hidden;
                            border-radius: 12px 12px 0 0;
                        }
                        .card-video {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: transform 0.5s ease;
                        }
                        .custom-card:hover .card-video {
                            transform: scale(1.05);
                        }
                        .ltn__feature-info {
                            padding: 20px;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                        }
                        .ltn__feature-info h3 {
                            font-size: clamp(1.1rem, 3vw, 1.5rem);
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                        .description-container {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                        }
                        .description-container p {
                            font-size: clamp(12px, 2vw, 14px);
                            color: #555;
                            line-height: 1.4;
                            height: 100%;
                            /* Fixed height for all descriptions */
                            min-height: 120px;
                            display: flex;
                            align-items: center;
                            margin: 0;
                        }
                        .box-shadow-1 {
                            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                        }
                        .scroll-buttons {
                            display: flex;
                            justify-content: center;
                            margin-top: 20px;
                            flex-wrap: wrap;
                            gap: 5px;
                        }
                        .scroll-btn {
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            margin: 0 5px;
                            border: none;
                            cursor: pointer;
                            background: #ccc;
                            transition: background 0.3s ease;
                        }
                        .scroll-btn.active {
                            background: #333;
                            transform: scale(1.2);
                        }
                        .section-title {
                            font-size: clamp(1.5rem, 5vw, 2.5rem);
                            text-align: center;
                            margin-bottom: 30px;
                        }
                        
                        @media (max-width: 1200px) {
                            .ltn__feature-info h3 {
                                font-size: 1.3rem;
                            }
                            .description-container p {
                                font-size: 13px;
                                min-height: 100px;
                            }
                        }
                        
                        @media (max-width: 992px) {
                            .custom-card {
                                width: calc(100% / ${this.state.visibleCards});
                                min-width: calc(100% / ${this.state.visibleCards});
                            }
                            .image-container {
                                height: 0;
                                padding-bottom: 56.25%;
                            }
                            .ltn__feature-info {
                                padding: 15px;
                            }
                            .description-container p {
                                min-height: 90px;
                            }
                        }
                        
                        @media (max-width: 768px) {
                            .custom-card {
                                width: calc(100% / ${this.state.visibleCards});
                                min-width: calc(100% / ${this.state.visibleCards});
                                padding: 0 5px;
                                margin: 0 3px;
                            }
                            .cards-container {
                                padding: 0 5px;
                            }
                            .custom-card:hover .card-content {
                                transform: scale(1.03);
                            }
                            .ltn__feature-info {
                                padding: 15px 10px;
                            }
                            .description-container p {
                                min-height: 80px;
                            }
                        }
                        
                        @media (max-width: 480px) {
                            .custom-card {
                                padding: 0 3px;
                                margin: 0 2px;
                            }
                            .scroll-btn {
                                width: 10px;
                                height: 10px;
                                margin: 0 3px;
                            }
                            .description-container p {
                                min-height: 100px;
                            }
                        }
                        `}
                    </style>
                </div>
            </div>
        );
    }
}
 
export default CaracteristiquesV2;