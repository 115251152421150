import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Shop_V1 = () => {
    return (
        <div>
            <Helmet>
                <title>Appartements à Vendre en Île-de-France | Appart9</title>
                <meta name="description" content="Découvrez notre sélection d'appartements à vendre en Île-de-France. Trouvez le logement idéal parmi nos différents types d'appartements et localisations." />
                <meta name="keywords" content="Recherche d'appartements, Choisir un appartement, Localisation des appartements, Types d'appartements, Immobilier Île-de-France" />
                <meta property="og:title" content="Appartements à Vendre en Île-de-France | Appart9" />
                <meta property="og:description" content="Explorez notre gamme d'appartements à vendre en Île-de-France. Des studios aux grands espaces, trouvez le logement parfait pour vous." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.appart9.com/appartements" />
                <meta property="og:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Appartements à Vendre en Île-de-France | Appart9" />
                <meta name="twitter:description" content="Explorez notre gamme d'appartements à vendre en Île-de-France. Des studios aux grands espaces, trouvez le logement parfait pour vous." />
                <meta name="twitter:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
            </Helmet>
            <Navbar />
            <PageHeader headertitle="Nos appartements" />
            <ShogGrid />
            <br />
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

export default Shop_V1;