import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actGetAppartements } from '../../slices/appartement/AppartSlice';
import Select from 'react-select';
import Sidebar from './shop-sidebar';
import emailjs from '@emailjs/browser';

const ShopGridV1 = () => {


  const [filters, setFilters] = useState({
    category: '',
    department: '',
    city: '',
    type: '',
    rooms: '',
    roomsRange: null,  // Plage pour 3 pièces et plus

    priceRange: [0, Infinity],  // Default price range
    surfaceRange: [0, Infinity],  // Default surface range
  });
  const [emailStatus, setEmailStatus] = useState(""); // État pour le message de confirmation
  const history = useHistory();
  const form = useRef();

  const [reviews, setReviews] = useState({
    appartment_id: '',
    rating: '',
    email: '',
    comment: '',
    fullname: ''
  });
  const [filteredAppartements, setFilteredAppartements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const dispatch = useDispatch();
  const { records, loading, error } = useSelector(state => state.appartement);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') || '';
    const department = searchParams.get('departement') || '';
    const roomsParam = searchParams.get('rooms') || '';


    let rooms = '';
    let roomsRange = null;

    if (roomsParam.includes('-')) {
      // C'est une plage (par exemple, '3-')
      const minRooms = parseInt(roomsParam.split('-')[0]);
      roomsRange = [minRooms, Infinity];
    } else if (roomsParam !== '') {
      // C'est une valeur exacte
      rooms = roomsParam;
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      type,
      department,
      roomsRange,
      rooms

    }));
    dispatch(actGetAppartements()); // Load apartments on startup
  }, [dispatch]);

  useEffect(() => {
    applyFilters(); // Apply filters when filters or records change
  }, [filters, records]);

  const applyFilters = () => {
    const { category, department, city, type, priceRange, roomsRange, rooms, surfaceRange } = filters;

    let filtered = [...records];



    // Filtrer par catégorie
    if (category) {
      filtered = filtered.filter(appartement => appartement.category && appartement.category.toLowerCase() === category.toLowerCase());
    }

    // Filtrer par département
    if (department) {
      filtered = filtered.filter(appartement => appartement.departement && appartement.departement.toLowerCase() === department.toLowerCase());
    }

    // Filtrer par ville
    if (city) {
      filtered = filtered.filter(appartement => appartement.ville && appartement.ville.toLowerCase().includes(city.toLowerCase()));
    }






    // Filtrage par nombre de pièces
    if (roomsRange) {
      // Si une plage est définie (par exemple, [3, Infinity])
      filtered = filtered.filter(appartement => appartement.rooms >= roomsRange[0]);
    } else if (rooms !== '') {
      // Si une valeur exacte est définie (par exemple, '0', '1', '2')
      const roomsInt = parseInt(rooms);

      // Filtrage pour les pièces exactes ou "3 pièces ou plus"
      if (roomsInt === 3) {
        filtered = filtered.filter(appartement => appartement.rooms >= 3);  // Inclure 3 pièces et plus
      } else {
        filtered = filtered.filter(appartement => appartement.rooms === roomsInt);
      }
    }




    // Filtrer par prix seulement si un filtre spécifique est sélectionné
    if (priceRange && (priceRange[0] !== 0 || priceRange[1] !== Infinity)) {
      filtered = filtered.filter(appartement => appartement.price >= priceRange[0] && appartement.price <= priceRange[1]);
    }

    // Filtrer par surface seulement si un filtre spécifique est sélectionné
    if (surfaceRange && (surfaceRange[0] !== 0 || surfaceRange[1] !== Infinity)) {
      filtered = filtered.filter(appartement => appartement.surface >= surfaceRange[0] && appartement.surface <= surfaceRange[1]);
    }
    // Sort by date (newest first)
    filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setFilteredAppartements(filtered);
    setCurrentPage(1); // Reset pagination after applying filters
  };

  // Modification de handleFilterChange pour gérer à la fois les filtres textuels et les plages de valeurs
  const handleFilterChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption ? selectedOption.value : ''
    }));
  };

  // Modification pour inclure priceRange et surfaceRange dans la logique de filtrage
  const handleRangeFilterChange = (filterValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filterValues // Inclure les nouvelles valeurs de plage de filtre
    }));
  };

  const resetFilters = () => {
    setFilters({
      category: '',
      department: '',
      city: '',
      type: '',
      rooms: '',
      roomsRange: null,
      priceRange: [0, Infinity],
      surfaceRange: [0, Infinity]
    });
  };
  const categoryOptions = [
    { value: '', label: 'Type de propriété' },
    { value: 'Appartement', label: 'Appartement' },
    { value: 'Studio', label: 'Studio' },
    { value: 'Maison', label: 'Maison' },
  ];

  const roomOptions = [
    { value: '', label: 'Nombre de pièces' },  // Option par défaut
    { value: '0', label: 'Studio' },  // Pour les studios
    { value: '1', label: '1 pièce' },
    { value: '2', label: '2 pièces' },
    { value: [3, Infinity], label: '3 pièces ou plus' }, // Utilisation d'un tableau pour les 3 pièces ou plus
  ];

  const departmentOptions = [
    { value: '', label: 'Département' },
    { value: 'Paris', label: 'Paris (75)' },
    { value: 'Seine-et-Marne', label: 'Seine-et-Marne (77)' },
    { value: 'Yvelines', label: 'Yvelines (78)' },
    { value: 'Essonne', label: 'Essonne (91)' },
    { value: 'Hauts-de-Seine', label: 'Hauts-de-Seine (92)' },
    { value: 'Seine-Saint-Denis', label: 'Seine-Saint-Denis (93)' },
    { value: 'Val-de-Marne', label: 'Val-de-Marne (94)' },
    { value: "Orly", label: "Orly" }
  ];

  const cityOptions = [
    { value: '', label: 'Ville' },
    { value: 'Paris', label: 'Paris' },
    { value: 'Le Marais', label: 'Le Marais' },
    { value: 'La Défense', label: 'La Défense' },
    { value: 'Saint-Germain-des-Prés', label: 'Saint-Germain-des-Prés' },
    { value: 'Champs-Élysées', label: 'Champs-Élysées' },
    { value: 'Montparnasse', label: 'Montparnasse' },
    { value: 'Boulogne-Billancourt', label: 'Boulogne-Billancourt' }
  ];
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      height: '50px',
      minHeight: '50px',
      borderColor: 'gray',
      boxShadow: 'none',
      borderWidth: '1px',
      '&:hover': {
        borderColor: '#304798',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '35px',
      padding: '0 6px',
      alignItems: 'center',
      display: 'flex',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'center',
      width: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
      display: 'flex',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '30px'
    }),
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = filteredAppartements.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredAppartements.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const sendEmail = async (e) => {
    e.preventDefault();

    emailjs.sendForm("service_v1afctl", "template_ytp08gz", form.current, {
      publicKey: "5nSvvdSIRS4sqijml",
    })
      .then(
        () => {
          history.push('/confirmation', { message: "Votre message a été envoyé avec succès." }); // Redirection vers la page de confirmation
        },
        (error) => {
          setEmailStatus("Échec de l'envoi du message. Veuillez réessayer."); // Message d'erreur en cas d'échec
          console.log("EmailJS Error: ", error.text);
        }
      );
  };

  return (
    <div>
      <style>
        {`
          .select-group {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            flex-wrap: wrap;
          }

          .select-input {
            flex: 1;
            max-width: 300px;
            min-width: 200px;
          }

          .filter-title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: left;
            color:black;
          }

          .reset-button {
            display: flex;
            justify-content: right;
            margin-bottom: 20px;
          }

          .reset-button button {
            background-color: #304798;
            color: white;
            border: none;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            border-radius: 1px;
          }

          .reset-button button:hover {
            background-color: #203567;
          }

          @media (max-width: 768px) {
            .filter-title {
              text-align: center;
            }

            .reset-button {
              justify-content: center;
            }

            .select-group {
              flex-direction: column;
              gap: 10px;
            }

            .select-input {
              width: 100%;
            }
          }
        `}
      </style>
      <div className="ltn__product-area ltn__product-gutter">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-100">
              <div className="ltn__shop-options">
                <div className="filter-title">
                  Filtrer les résultats
                </div>
                <ul className="justify-content-start">
                  <li>
                    <div className="short-by text-center">

                      <Select
                        name="rooms"
                        options={roomOptions}
                        onChange={handleFilterChange}
                        className="select-input"
                        isClearable
                        placeholder="Nombre de pièces"
                        styles={customSelectStyles}

                      />
                    </div>
                  </li>
                  <li>
                    <div className="short-by text-center">
                      <Select
                        name="department"
                        options={departmentOptions}
                        onChange={handleFilterChange}
                        className="select-input"
                        isClearable
                        placeholder="Département"
                        styles={customSelectStyles}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="short-by text-center">
                      <Select
                        name="city"
                        options={cityOptions}
                        onChange={handleFilterChange}
                        className="select-input"
                        isClearable
                        placeholder="Ville"
                        styles={customSelectStyles}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>Error: {error}</div>
              ) : filteredAppartements.length === 0 ? ( // Vérification si aucun résultat
                <div>Aucun appartement trouvé avec les critères de filtrage.</div>
              ) : (
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="liton_product_grid">
                    <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                      <div className="row">
                        {currentProducts.map((product) => (
                          <div className="col-xl-6 col-sm-6 col-12" key={product._id}>
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                              <div className="product-img go-top">
                                <Link to={`/product-details/${product._id}`}>
                                  <img src={product.image} alt={product.title} />
                                </Link>
                              </div>
                              <div className="product-info">
                                <div className="product-badge">
                                  <ul>
                                    <li className="sale-badg">{product.type === "Vente" ? "À Vendre" : "À Louer"}</li>
                                  </ul>
                                </div>
                                <h2 className="product-title go-top">
                                  <Link to={`/product-details/${product._id}`}>{product.title}</Link>
                                </h2>
                                <div className="product-img-location go-top">
                                  <ul>
                                    <li>
                                      <Link to="/contact"><i className="flaticon-pin" /> {product.quartier}</Link>
                                    </li>
                                  </ul>
                                </div>
                                <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                  <li><span>{product.bedrooms} </span> Bed</li>
                                  <li><span>{product.bathrooms} </span> Bath</li>
                                  <li><span>{product.surface} </span> Square Ft</li>
                                </ul>
                                <div className="product-hover-action">
                                  <ul>
                                    <li>
                                      <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
                                        <i className="flaticon-expand" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
                                        <i className="flaticon-heart-1" />
                                      </a>
                                    </li>
                                    <li className="go-top">
                                      <Link to={`/product-details/${product._id}`} title="Product Details">
                                        <i className="flaticon-add" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="product-info-bottom">
                                <div className="product-price">
                                  <span>
                                    {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €

                                    {product.priceLabel && !product.priceLabel.includes('€') && (
                                      <label>{product.priceLabel}</label>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Pagination */}
                  <div className="ltn__pagination-area text-center">
                    <div className="ltn__pagination">
                      <ul>
                        <li>
                          <Link
                            to="#"
                            onClick={() => paginate(currentPage - 1)}
                            style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}
                          >
                            <i className="fas fa-angle-double-left" />
                          </Link>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                          <li key={index}>
                            <Link
                              to="#"
                              onClick={() => paginate(index + 1)}
                              className={index + 1 === currentPage ? 'active' : ''}
                              style={{
                                color: index + 1 === currentPage ? 'white' : '',
                                backgroundColor: index + 1 === currentPage ? '#304798' : '',
                                borderRadius: '50%',
                                padding: '10px 15px',
                              }}
                            >
                              {index + 1}
                            </Link>
                          </li>
                        ))}
                        <li>
                          <Link
                            to="#"
                            onClick={() => paginate(currentPage + 1)}
                            style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}
                          >
                            <i className="fas fa-angle-double-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Sidebar Component */}
            <Sidebar onFilterChange={handleRangeFilterChange} />
            {/* Form Widget */}
            <h2 className="ltn__widget-title ltn__widget-title-border-2 ">Laissez un message pour réserver</h2>

            <div className="widget ltn__form-widget">
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  placeholder="Votre nom*"
                  required
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Votre e-Mail*"
                  required
                />
                <textarea
                  id="message"
                  name="message"
                  placeholder="Écrivez un message..."
                  required
                ></textarea>
                <button type="submit" className="btn theme-btn-1">Envoyer le message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopGridV1;
